import _ from 'lodash';
import usersService from '../../services/request/users/usersService';
import loader from '../../mixins/loader';
import notifications from '../../mixins/notifications';
import EventBus from '../../events/EventBus';

export default {
  mixins: [loader, notifications],
  data: () => ({
    page: 1,
    tableLoad: false,
    confirmDelete: false,
    uuidToDelete: '',
    editUserDialog: false,
    editUser: null,
    users: [],
    filterProps:{},
    headers: [
      {
        value: 'photo',
      },
      {
        text: "Ім'я",
        value: 'name',
      },
      {
        text: 'E-mail',
        value: 'email',
        align: 'left',
      },
      {
        text: 'Телефон',
        value: 'phone',
        align: 'left',
      },
      {
        text: 'Дата реєстрації',
        value: 'time_created',
        align: 'left',
      },
      {
        value: 'actions',
        sortable: false,
        align: 'end',
      },
    ],
  }),
  methods: {
    async onFilter(filterProps) {
      this.filterProps = filterProps
      console.log('filterProps', filterProps);
      this.page = 1;
      this.users = [];
      await this.getUsersList(filterProps,true,false,true);
    },
    paginate(event) {
      this.page = event.page;
      this.perPage = event.itemsPerPage;
    },
    onDeleteUser(uuid) {
      this.confirmDelete = true;
      this.uuidToDelete = uuid;
    },
    async deleteUser(uuid) {
      try {
        this.setLoading(true);
        await usersService.deleteUser(uuid);
        this.setLoading(false);
        this.confirmDelete = false;
        this.uuidToDelete = '';
        this.setSuccessNotification('Користувача успішно видалено!');
        await this.getUsersList();
      } catch (e) {
        this.setLoading(false);
        this.setErrorNotification(e);
      }
    },
    async emitPageChange() {
      try {
        await this.getUsersList(this.filterProps,true);
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    getUniqueUsers(array) {
      return _.uniqBy(array, 'uuid');
    },
  },
  async mounted() {
    this.page = 1;
    EventBus.$on('added-user', async () => {
      this.page = 1;
      this.users = [];
      this.setLoading(true);
      await this.getUsersList();
    });
    EventBus.$on('updated-user', async () => {
      this.users = [];
      this.setLoading(true);
      await this.getUsersList(this.filterProps,false,true)
    });
    this.setLoading(true);
    await this.getUsersList();
  },
};
