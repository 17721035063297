<template>
      <thead>
        <tr>
          <th class="icon text-center" v-if="canEdit"><VIcon dense> mdi-cogs</VIcon></th>
          <th class="icon text-center"><VIcon dense>mdi-account-box</VIcon></th>
          <th class="text-left ">
            <div class="header "  >
              <span>{{$t('form.name')}}</span>
              <VTextField dense hide-details solo v-model="filter.name" clearable />
            </div>
          </th>
          <th class="text-left md">
            <div class="header">
              <span>{{$t('form.surname')}}</span>
              <VTextField dense hide-details solo v-model="filter.surname" clearable />
            </div>
          </th>
          <th class="text-left">
            <div class="header">
              <span>{{$t('form.email')}}</span>
              <VTextField dense hide-details solo v-model="filter.email" clearable  />
            </div>
          </th>
          <th class="text-left">
            <div class="header">
              <span>{{$t('table.phone')}}</span>
              <VTextField v-on:keypress="onlyNumber($event)" dense hide-details solo v-model="filter.phone"   clearable />
            </div>
          </th>
          <th class="text-left">
            <div class="header">
              <span>{{$t('table.register_data')}}</span>
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-60"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <VTextField
                    v-model="filter.time_created"
                    readonly
                    append-icon="mdi-calendar"
                    dense
                    solo
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  />
                </template>
                <v-date-picker
                  :locale="currentLanguage.code"
                  no-title
                  v-model="filter.time_created"
                  @input="dateMenu = false"
                  :max="NextYear"
                  min="2021"
                ></v-date-picker>
              </v-menu>
            </div>
          </th>
          <th class="text-left">
            <div class="header">
              <span>{{$t('table.status')}}</span>
              <v-select clearable :menu-props="{bottom:true,offsetY:true}" :items="states" :item-value="'id'" dense hide-details solo v-model="filter.id_state">
                <template v-slot:selection="{ item }">
                  <div class="d-flex align-center justify-content-start">
                    <StatusCircle
                      :status-name="item.id"
                      :type="'supply'"
                      class="pb-1"
                    />
                    <div class="elispsis">
                      {{ $t(item.name) }}
                    </div>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div class="d-flex align-center justify-content-start">
                    <StatusCircle
                      :status-name="item.id"
                      :type="'supply'"
                      class="pb-1"
                    />
                    <div class="elispsis">
                      {{ $t(item.name) }}
                    </div>
                </div>
              </template>
              </v-select>
            </div>
          </th>
        </tr>
      </thead>
</template>

<script>
import _ from "lodash";
import user from "../../mixins/user";
import { onlyNumber } from "../../mixins/helpers";
import {mapGetters} from 'vuex'
import StatusCircle from "@/components/move-list/StatusCircle.vue";

export default {
  components: {
    StatusCircle,
  },
  mixins:[user],
  name: "UsersHeader",
  data: () => ({
    dateMenu: false,
    filter: {
      name: "",
      surname: "",
      email: "",
      phone: "",
      time_created: "",
    },
    states: [{
        id: "200",
        name: "users.state_200"
      },
      {
        id: "210",
        name: "users.state_210"
      },
      {
        id: "250",
        name: "users.state_250"
      }],
  }),
  computed: {
     ...mapGetters(["currentLanguage"]),
    NextYear() {
      const event = new Date().toISOString().substr(0, 12);
      return event;
    }
  },
  watch: {
    isLoading:{
      deep:true,
      handler(e){
        console.log(e)
      }
    },
    filter: {
      deep: true,
      handler(e) {
        const resultProps = {
          uselike: true
        };
        Object.keys(this.filter).forEach(property => {
          if (this.filter[property]) resultProps[property] = this.filter[property];
        });
        if(resultProps.phone){
          resultProps.phone = resultProps.phone.replace(/[^+\d]/g, '')
        }
        this.onSearch(resultProps);
     }
    },
  },

  methods: {
    onlyNumber,
    onSearch: _.debounce(function(resultProps) {
      this.$emit("filter", resultProps);
    }, 500)
  },

  props: ["reset"]
};
</script>

<style scoped lang="scss">
.pagination-items {
  &-header {
    border-bottom: 1px solid #f2f2f2;
    background: transparent;

    .td {
      padding: 4px;
      text-align: center;
      input {
        border: 1px solid #eeeded;
        padding: 4px;
        &:disabled {
          background-color: #eeeded;
        }
      }
      span {
        font-family: Roboto;
        margin-bottom: 15px;
        display: block;
        color: #676767;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
      }
      &.date {
        input,
        select {
          border: unset;
        }
      }
    }
  }
}
</style>
