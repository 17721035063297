<template>
  <VDialog
    v-model="visibility"
    @click:outside="showConfirmDialog"
    :persistent="changed"
    content-class="cross__dialog"
    max-width="600px"
  >
    <VCard :loading="loading">
      <div class="cross__inner">
        <VBtn fab small color="white" elevation="0" @click="showConfirmDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VCardTitle>
        <VRow no-gutters justify="space-between">
        <div class="headline" :class="{ 'text-body-1': $vuetify.breakpoint.xs }">{{
          `${$t("btn.update")} ${$t("users.user")}`
        }}</div>

        <VBtn color="error" text class="br-10 text-transform-none" @click="blockUser(true)" v-if="canBlock && user.id_state == '210' ">Заблокувати<VIcon class="ml-2" small> mdi-block-helper</VIcon></VBtn>
        <VBtn color="success" text class="br-10 text-transform-none" @click="blockUser(false)" v-if="canBlock && user.id_state == '250'">Розблокувати<VIcon class="ml-2" small> mdi-block-helper</VIcon></VBtn>
        </VRow>
      </VCardTitle>
      <VCardText>
        <VContainer>
          <VRow class="pb-0">
            <VCol cols="12" md="6" sm="12">
              <VTextField
                outlined
                dense
                :label="$t('form.name')"
                required
                v-model="user.name"
                :error-messages="nameErrors"
                v-on:keypress="noEngText($event)"
                @paste="noEngTextPasted($event)"
                :hint="onEngText ? '' : $t('form.hint.NameNoEngText')"
                hide-details="auto"
                @input="noEngText($event, false)"
              ></VTextField>
            </VCol>
            <VCol cols="12" md="6" sm="12">
              <VTextField
                outlined
                dense
                :label="$t('form.surname')"
                required
                v-model="user.surname"
                :error-messages="surnameErrors"
                @paste="noEngTextPasted($event)"
                v-on:keypress="noEngText($event)"
                :hint="onEngText ? '' : $t('form.hint.SurnameNoEngText')"
                hide-details="auto"
                @input="noEngText($event, false)"
              ></VTextField>
            </VCol>
            <VCol cols="12" md="6" sm="12">
              <VTextField
                class="inputPhone"
                outlined
                dense
                :label="$t('form.phone_number')"
                required
                prefix="+38"
                v-on:keypress="onlyNumber($event)"
                :maxLength="20"
                v-model="user.phone"
                :error-messages="phoneErrors"
                hide-details="auto"
                @paste="noEngTextPasted($event, true)"
                autocomplete="new-password"
              >
              </VTextField>
            </VCol>
            <VCol cols="12" md="6" sm="12">
              <VFileInput
                dense
                outlined
                small-chips
                v-model="user.image"
                accept="image/png, image/jpeg"
                prepend-icon=""
                prepend-inner-icon="mdi-camera"
                :label="$t('form.photo')"
                hide-details="auto"
              />
            </VCol>
            <VCol cols="12" md="12" sm="12" v-if="accessRole">
              <v-autocomplete
                :items="depots"
                :item-value="'uuid'"
                :item-text="'name'"
                outlined
                :label="$t('form.storage')"
                :no-data-text="$t('table.noData')"
                dense
                v-model="user.deport_uuid"
                :error-messages="uuidErrors"
                hide-details="auto"
                small-chips
                deletable-chips
              />
            </VCol>
          </VRow>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0">
          <VCol cols="6">
            <VBtn
              class="text-white text-transform-none br-10 w-100"
              @click="onClose"
              block
              dark
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.cancel") }}
            </VBtn>
          </VCol>
          <VCol cols="6">
            <VBtn
              class="success-bg text-white text-transform-none br-10 w-100"
              block
              @click="onUpdate"
              :disabled="!changed"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.update") }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onClose"
    />

    <ConfirmDialog
      v-if="canBlock"
      :visible="blockDialog"
      @close="blockDialog = false"
      @onConfirm="onBlock"
      @onCancel="blockDialog = false"
      :typeAlert="blocked ? 'error' : 'success'"
    >
    <template v-slot:header>
      {{blockTitle}}
    </template>
    <template v-slot:description>
     {{blockDesc}}
    </template>
    </ConfirmDialog>
  </VDialog>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import language from "../../mixins/language";
import notifications from "../../mixins/notifications";
import usersService from "../../services/request/users/usersService";
import EventBus from "../../events/EventBus";
import depotService from "../../services/request/depot/depotService";
import user from "../../mixins/user";
import phoneMask from "../../validators/phoneMask";
import { noEngText, onlyNumber, noEngTextPasted } from "../../mixins/helpers";
import noEngTextValidator from "@/validators/noEngText";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";

export default {
  name: "EditUserDialog",
  mixins: [language, notifications, validationMixin, user],
  validations: {
    user: {
      deport_uuid: {
        // eslint-disable-next-line func-names
        required: requiredIf(function() {
          return this.accessRole;
        })
      },
      name: { required, noEngTextValidator },
      surname: { required, noEngTextValidator},
      phone: { required, phoneMask }
    }
  },
  data: () => ({
    onEngText: true,
    inputUser: {},
    user: {
      uuid: "",
      name: "",
      surname: "",
      email: "",
      phone: "",
      deport_uuid: "",
      image: null
    },
    visibleConfirm: false,
    depots: [],
    accessRole: false,
    loading: false,
    changed: false,
    blocked: false,
    blockTitle: null,
    blockDesc: null,
    blockDialog: false,
  }),
  components: {
    ConfirmLeftDialog,
    ConfirmDialog,
  },
  async mounted() {
    // orderDialog("role", this.role);
    this.updateRoleInfo();
    try {
      this.depots = await depotService.getDepotsList();
    } catch (e) {
      this.setErrorNotification(e);
    }
    this.user = {
      uuid: this.userItem.uuid,
      name: this.userItem.name,
      surname: this.userItem.surname,
      deport_uuid: this.userItem.uuid_deport,
      email: this.userItem.email,
      id_state: this.userItem.id_state,
      phone: this.userItem.phone.replace("+38", ""),
    };
    this.inputUser = {
      ...this.user
    };
  },
  methods: {
    noEngText,
    onlyNumber,
    noEngTextPasted,
    showConfirmDialog() {
      if (this.changed) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    blockUser(check) {
      console.log(check)
      this.blocked = check
      if (this.blocked) {
        this.blockTitle = this.$t('confirmDialog.block_user.title')
        this.blockDesc = this.$t('confirmDialog.block_user.desc')
      }
      else {
        this.blockTitle = this.$t('confirmDialog.unblock_user.title')
        this.blockDesc = this.$t('confirmDialog.unblock_user.desc')
      }
      this.blockDialog = true
    },
    updateRoleInfo() {
      // orderDialog(123123);
      // orderDialog(this.permissions.can_create_user_deport);
      if (this.role === "employee" || this.role === "client" || this.role === "manager") {
        if (this.permissions.can_create_user_deport) {
          this.accessRole = true;
          // this.validations.user.deport_uuid.required = true;
        }
      }
    },
    onBlock() {
      if (this.blocked) {
        this.user.id_state = "250"
      }
      else this.user.id_state = "210"

      this.onUpdate()
    },
    onClose() {
      this.$v.$reset();
      this.visibility = false;
    },
    async onUpdate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append("uuid", this.user.uuid);
          if (this.user.image) {
            formData.append("image", this.user.image);
          }

          const changedData = this.$getChangedData(this.user, this.inputUser);
          changedData.forEach(data => {
            formData.append(data.field, data.value);
          });
          await usersService.updateUser(formData);
          this.setSuccessNotification(this.$t("users.user_update_success"));
          this.loading = false;
          EventBus.$emit("updated-user");
          this.visibility = false;
          this.$emit("resetFilter", true);
          this.$v.$reset();
        } catch (e) {
          this.loading = false;
          this.setErrorNotification(e);
        }
      }
    }
  },
  props: {
    visible: {
      required: true
    },
    userItem: {
      required: true
    },
    role: {
      required: true,
      type: String
    }
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.user, this.inputUser);
      }
    },
    role: "updateRoleInfo"
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    },
    uuidErrors() {
      const errors = [];
      if (!this.$v.user.deport_uuid.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.deport_uuid.required && errors.push(this.$t("form.errors.StorageRequired"));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.user.name.$dirty) {
        return errors;
      }
      if (!this.$v.user.name.noEngTextValidator) {
        errors.push(this.$t("form.hint.NameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.name.required && errors.push(this.$t("form.errors.NameRequired"));
      return errors;
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.user.surname.$dirty) {
        return errors;
      }
      if (!this.$v.user.surname.noEngTextValidator) {
        errors.push(this.$t("form.hint.SurnameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.surname.required && errors.push(this.$t("form.errors.SurnameRequired"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.user.phone.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.phone.required && errors.push(this.$t("form.errors.PhoneNumberRequired"));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.phone.phoneMask && errors.push(this.$t("form.errors.PhoneNumberMustBeLike"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.email.required && errors.push(this.$t("form.errors.PasswordRequired"));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.email.email && errors.push(this.$t("form.errors.PasswordMustBeLike"));
      return errors;
    }
  }
};
</script>

<style scoped></style>
