<template>
  <VDialog
    @click:outside="showConfirmDialog"
    v-model="visibility"
    :persistent="isChanged"
    max-width="600px"
     content-class="cross__dialog"
     scrollable
  >
    <VCard :height="$vuetify.breakpoint.smAndDown ? '500' : ''">
          <div class="cross__inner">
      <VBtn fab small  color="white" elevation="0" @click="showConfirmDialog">
        <VIcon>mdi-close</VIcon>
      </VBtn>
      </div>
      <VCardTitle>
        <span class="headline"  :class="{ 'text-body-1': $vuetify.breakpoint.xs }">{{$t('form.add')}} {{ addName }}</span>
     </VCardTitle>
      <VCardText>
        <div class="text-center" v-if="loading">
          <VProgressCircular
            :size="50"
            color="primary"
            indeterminate
          ></VProgressCircular>
        </div>
        <VContainer v-else>
          <VRow>
            <VCol cols="12" md="6" sm="12">
              <VTextField
                outlined dense
                autocomplete
                :label="$t('form.name')"
                required
                v-model="user.name"
                :error-messages="nameErrors"
                v-on:keypress="noEngText($event)"
                @paste="noEngTextPasted($event)"
                :hint="onEngText  ? '' : $t('form.hint.NameNoEngText')"
                @input="noEngText($event,false)"
                hide-details="auto"
              ></VTextField>
            </VCol>
            <VCol cols="12" md="6" sm="12">
              <VTextField
                autocomplete
                outlined dense
                :label="$t('form.surname')"
                required
                v-model="user.surname"
                :error-messages="surnameErrors"
                 v-on:keypress="noEngText($event)"
                 @paste="noEngTextPasted($event)"
                :hint="onEngText  ? '' : $t('form.hint.SurnameNoEngText')"
                 hide-details="auto"
                 @input="noEngText($event,false)"
              ></VTextField>
            </VCol>
            <VCol cols="12" md="6" sm="12">
              <VTextField
                autocomplete="new-password"
                outlined dense
                :label="$t('form.email')"
                required
                v-model="user.email"
                :error-messages="emailErrors"
                @paste="noEngTextPasted($event)"
                 hide-details="auto"
              ></VTextField>
            </VCol>
            <VCol cols="12" md="6" sm="12">
              <VTextField
                outlined dense
                autocomplete="new-password"
                :label="$t('form.password')"
                placeholder=""
                required
                v-model="user.password"
                :error-messages="passwordErrors"
                :type="passwordVisible ? 'text' : 'password'"
                :append-icon=" passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="passwordVisible = !passwordVisible"
                 hide-details="auto"
              ></VTextField>
            </VCol>
            <VCol cols="12" md="6" sm="12">
              <VTextField
                class="inputPhone"
                outlined dense
                 autocomplete="new-password"
                :label="$t('form.phone_number')"
                required
                v-model="user.phone"
                :error-messages="phoneErrors"
                prefix="+38"
                v-on:keypress="onlyNumber($event)"
                :maxLength="20"
                @paste="noEngTextPasted($event,true)"
                hide-details="auto"
              >

              </VTextField>
            </VCol>
            <VCol cols="12" md="6" sm="12">
              <VFileInput
                dense
                outlined
                v-model="user.image"
                accept="image/png, image/jpeg"
                prepend-icon=""
                prepend-inner-icon="mdi-camera"
                :label="$t('form.photo')"
                hide-details="auto"
              />
            </VCol>
            <VCol cols="12" md="12" sm="12" v-if="accessRole" >
              <v-autocomplete
                :items="depots"
                :item-value="'uuid'"
                :item-text="'name'"
                outlined
                :label="$t('form.storage')"
                :no-data-text="$t('table.noData')"
                dense
                v-model="user.deport_uuid"
                :error-messages="uuidErrors"
                hide-details="auto"
                small-chips
                deletable-chips
              />
            </VCol>
          </VRow>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0">
          <VCol cols="6">
            <VBtn
              @click="onClose"
              block
              class="text-white text-transform-none br-10 w-100"
              dark
             :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t('btn.cancel') }}
            </VBtn>
          </VCol>
          <VCol cols="6">
            <VBtn
              block
              class="success-bg text-white text-transform-none br-10 w-100"
              @click="onCreate"
              :disabled="!isChanged"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"

            >
             {{ $t('btn.create') }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
    <ConfirmLeftDialog
    :visible="visibleConfirm"
    @close="visibleConfirm = false"
    @delete="onClose"
    />
  </VDialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email, requiredIf } from 'vuelidate/lib/validators';
import ConfirmLeftDialog from '@/components/dialog/ConfirmLeftDialog.vue';
import language from '../../mixins/language';
import notifications from '../../mixins/notifications';
import usersService from '../../services/request/users/usersService';
import EventBus from '../../events/EventBus';
import passwordMask from '../../validators/passwordMask';
import depotService from '../../services/request/depot/depotService';
import user from '../../mixins/user';
import phoneMask from '../../validators/phoneMask';
import {noEngText, onlyNumber ,noEngTextPasted} from "../../mixins/helpers";
import noEngTextValidator from "@/validators/noEngText";

export default {
  name: 'AddUserDialog',
  mixins: [language, validationMixin, notifications, user],
  validations: {
    user: {
      name: { required, noEngTextValidator},
      surname: { required, noEngTextValidator},
      deport_uuid: {
        // eslint-disable-next-line func-names
        required: requiredIf(function () {
          return this.accessRole;
        }),
      },
      email: { required, email },
      password: { required, passwordMask },
      phone: { required, phoneMask },
    },
  },
  data: () => ({
    onEngText:true,
    user: {
      name: '',
      surname: '',
      email: '',
      password: '',
      phone: '',
      deport_uuid: '',
      image: null,
    },
    visibleConfirm:false,
    depots: [],
    accessRole: false,
    passwordVisible: false,
    loading: false,
    isChanged:false
  }),
  async mounted() {
    // orderDialog('role', this.role);
    this.updateRoleInfo();
    try {
      this.depots = await depotService.getDepotsList();
    } catch (e) {
      this.setErrorNotification(e);
    }
  },
  watch: {
    role: 'updateRoleInfo',
        user: {
      deep: true,
      handler() {
        const values = Object.values(this.user);
             if(values.filter((item) => item).length){
          this.isChanged = true
        }else{
           this.isChanged = false
        }
      },
    },
  },
  methods: {
  noEngText,
  onlyNumber,
  noEngTextPasted,
    showConfirmDialog(){
      if(this.isChanged){
      this.visibleConfirm = true
      }else{
        this.onClose();
      }
    },
    updateRoleInfo() {
      if (this.role === 'employee' || this.role === 'client' || this.role === 'manager') {
        if (this.permissions.can_create_user_deport) {
          // orderDialog(this.permissions.can_create_user_deport);
          this.accessRole = true;
          // this.validations.user.deport_uuid.required = true;
        }
      }
    },
    onClose() {
      this.user = [];
      this.$v.$reset();
      this.visibility = false;
    },
    async onCreate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append('name', this.user.name);
          formData.append('surname', this.user.surname);
          formData.append('email', this.user.email);
          if (this.accessRole) {
            formData.append('uuid_deport', this.user.deport_uuid);
          }
          formData.append('password', this.user.password);
          formData.append('phone', `+38${this.user.phone}`);
          formData.append('type', this.role);
          if (this.user.image) {
            formData.append('image', this.user.image);
          }
          await usersService.addUser(formData);
          this.setSuccessNotification(this.$t('users.user_add_success'));
          this.loading = false;
          this.clearUser();
          this.$v.$reset();
          EventBus.$emit('added-user');
          this.visibility = false;
        } catch (e) {
          this.loading = false;
          this.setErrorNotification(e);
        }
      }
    },
    clearUser() {
      Object.keys(this.user).forEach((key) => {
        this.user[key] = null;
      });
    },
  },
  components:{
    ConfirmLeftDialog
  },
  props: {
    addName: {
      required: true,
    },
    visible: {
      required: true,
    },
    role: {
      required: true,
      type: String,
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit('close');
      },
    },
    uuidErrors() {
      const errors = [];
      if (!this.$v.user.deport_uuid.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.deport_uuid.required
      && errors.push(this.$t('form.errors.StorageRequired'));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.user.name.$dirty) {
        return errors;
      }
      if (!this.$v.user.name.noEngTextValidator) {
        errors.push(this.$t("form.hint.NameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.name.required
      && errors.push(this.$t('form.errors.NameRequired'));
      return errors;
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.user.surname.$dirty) {
        return errors;
      }
      if (!this.$v.user.surname.noEngTextValidator) {
        errors.push(this.$t("form.hint.SurnameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.surname.required
      &&  errors.push(this.$t('form.errors.SurnameRequired'));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.user.phone.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.phone.required
      && errors.push(this.$t('form.errors.PhoneNumberRequired'));
      // eslint-disable-next-line no-unused-expressions
      if(this.user.phone){
        !this.$v.user.phone.phoneMask
        && errors.push(this.$t('form.errors.PhoneNumberMustBeLike'));
      }
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.email.required
      &&  errors.push(this.$t('form.errors.EmailRequired'));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.email.email
      &&  errors.push(this.$t('form.errors.EmailMustBeValid'));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.user.password.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.password.required
      &&  errors.push(this.$t('form.errors.PasswordRequired'));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.password.passwordMask
      && errors.push(this.$t('form.errors.PasswordMustBeLike'));
      return errors;
    },
  },
};
</script>

<style scoped>
</style>
