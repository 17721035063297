<template>
  <VDialog
   content-class="cross__dialog"
    v-model="visibility"
    max-width="500px"
    @click:outside="showConfirmDialog"
    :persistent="changed"
    scrollable
  >
    <VCard >
      <div class="cross__inner">
        <VBtn fab small  color="white" elevation="0" @click="showConfirmDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VCardTitle>
        <span class="font-20"  :class="{ 'text-body-1': $vuetify.breakpoint.xs }">{{`${$t('form.edit')} ${$t('users.client')}а`}}</span>
      </VCardTitle>
      <VDivider class="mb-3"/>
      <VCardText>
        <VContainer class="py-0">
          <VRow class="align-center mt-3 justify-center pb-0">
            <VCol cols="12" md="8" class="text-center">
              <label
                for="customer_photo"
                class="cursor-pointer customer-photo-label"
              >
                <VIcon
                  size="70"
                  v-if="!photoPreview && !userItem.path_img"
                >mdi-account-circle</VIcon>
                <img
                  :src="photoPreview"
                  alt=""
                  width="80"
                  v-else-if="photoPreview">
                <img
                  :src="userItem.path_img"
                  alt="" width="80"
                  v-else-if="userItem.path_img && !photoPreview">
              </label>
              <VFileInput
                id="customer_photo"
                class="d-none"
                v-model="customer.photo"
                accept="image/*"
                @change="makePreview"
              /><br>
              <VBtn
                v-if="customer.photo"
                @click="customer.photo = null; photoPreview = null"
                depressed
                class="br-10"
              >
                {{$t('btn.delete')}}
              </VBtn>
            </VCol>
            <VCol cols="12" md="8" class="mb-2 text-center">
              <VBtn color="error" text class="br-10 text-transform-none" @click="blockUser(true)" v-if="canBlock && customer.id_state == '210' ">Заблокувати<VIcon class="ml-2" small> mdi-block-helper</VIcon></VBtn>
              <VBtn color="success" text class="br-10 text-transform-none" @click="blockUser(false)" v-if="canBlock && customer.id_state == '250'">Розблокувати<VIcon class="ml-2" small> mdi-block-helper</VIcon></VBtn>
            </VCol>
            <VCol cols="12" md="8" class="py-0">
              <VTextField
                class="br-10"
                :label="$t('form.name')"
                dense
                color="green"
                outlined
                v-model="customer.name"
                :error-messages="nameErrors"
                v-on:keypress="noEngText($event)"
                @paste="noEngTextPasted($event)"
                :hint="onEngText  ? '' : $t('form.hint.NameNoEngText')"
                @input="noEngText($event,false)"
              />
            </VCol>
            <VCol cols="12" md="8" class="py-0">
              <VTextField
                class="br-10"
                :label="$t('form.surname')"
                dense
                outlined
                color="green"
                v-model="customer.surname"
                :error-messages="surnameErrors"
                v-on:keypress="noEngText($event)"
                @paste="noEngTextPasted($event)"
                :hint="onEngText  ? '' : $t('form.hint.SurnameNoEngText')"
                @input="noEngText($event,false)"
              />
            </VCol>
            <VCol cols="12" md="8" class="py-0">
              <VTextField
                class="br-10 inputPhone"
                :label="$t('form.phone_number')"
                dense
                color="green"
                outlined
                v-model="customer.phoneNumber"
                :error-messages="phoneErrors"
                prefix="+38"
                v-on:keypress="onlyNumber($event)"
                :maxLength="20"
                @paste="noEngTextPasted($event,true)"
                autocomplete="new-password"
              >

              </VTextField>
            </VCol>
            <VCol cols="12" md="8" class="py-0">
              <VTextField
                class="br-10"
                :label="$t('form.email')"
                dense
                outlined
                v-model="customer.email"
                :error-messages="emailErrors"
                color="green"
              />
            </VCol>
            <VCol cols="12" md="8" class="py-0" >
              <VTextField
                class="br-10"
                :label="$t('form.company')"
                dense
                autocomplete="off"
                outlined
                v-model="customer.company"
                color="green"
              />
            </VCol>
            <VCol cols="12" md="8" class="py-0" v-if="accessRole">
              <v-autocomplete
                class="br-10"
                :items="depots"
                :item-value="'uuid'"
                :item-text="'name'"
                outlined
                :label="$t('form.storage')"
                :no-data-text="language.NO_DATA_IS_AVAILABLE"
                dense
                v-model="customer.deport_uuid"
                :error-messages="uuidErrors"
                color="green"
                 small-chips
                deletable-chips
              />
            </VCol>
            <VCol cols="12" md="8" class="py-0" v-if="type !== 'crm'">
              <VTextField
                class="br-10"
                label="NS Api Key"
                dense
                outlined
                v-model="customer.np_apikey"
                color="green"
              />
            </VCol>
            <VCol cols="12" md="8" class="py-0" v-if="permissions.can_view_plans_list && type !== 'crm'">
              <v-autocomplete
                class="br-10"
                :label="$t('form.payment_plan')"
                dense
                outlined
                v-model="customer.uuid_user_plan"
                :menu-props="{bottom:true, offsetY:true}"
                color="green"
                :items="Object.values(paymentPlansList)"
                :item-text="'name'"
                :item-value="'uuid'"
                small-chips
              />
            </VCol>
            <VCol cols="12" md="8" class="py-0" >
              <VMenu
                ref="datePikerDialog"
                :disabled="!permissions.can_edit_date_end_crm"
                v-model="datePikerDialog"
                :close-on-content-click="false"
                :return-value.sync="customer.date_crm_end"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <VTextField
                    class="br-10"
                    v-model="customer.date_crm_end"
                    :label="$t('form.crm_end_date')"
                    :disabled="!permissions.can_edit_date_end_crm"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    color="green"
                  ></VTextField>
                </template>
                <VDatePicker
                  v-model="customer.date_crm_end"
                  no-title
                  scrollable
                  :locale="currentLanguage.code"
                  :min="new Date().toISOString().substr(0, 10)"
                >
                  <VSpacer></VSpacer>
                  <VBtn
                    text
                    color="primary"
                    @click="datePikerDialog = false"
                  >
                    Cancel
                  </VBtn>
                  <VBtn
                    text
                    color="primary"
                    @click="$refs.datePikerDialog.save(customer.date_crm_end)"
                  >
                    OK
                  </VBtn>
                </VDatePicker>
              </VMenu>
            </VCol>
            <VCol cols="12" md="8" class="py-0" >
              <VTextField
                class="br-10"
                label="CRM apiKey"
                dense
                :disabled="true"
                outlined
                v-model="customer.uuid_crm_apikey"
                color="green"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VContainer>
          <VRow class="my-0 py-0">
            <VCol cols="6" class="my-0 py-0">
              <VBtn
                @click="visibility = false"
                block
                class="w-100 br-10 text-transform-none"
                dark
                 :class="{ 'text-caption': $vuetify.breakpoint.xs }"
              >
                {{$t('btn.cancel')}}
              </VBtn>
            </VCol>
            <VCol cols="6" class="my-0 py-0">
              <VBtn
                class="text-white text-transform-none br-10 w-100"
                :class="{ 'success-bg': changed,'text-caption': $vuetify.breakpoint.xs }"
                block
                :disabled="!changed"
                @click="onUpdate"
              >
                {{$t('btn.update')}}
              </VBtn>
            </VCol>
          </VRow>
        </VContainer>
      </VCardActions>
    </VCard>
        <ConfirmLeftDialog
    :visible="visibleConfirm"
    @close="visibleConfirm = false"
    @delete="onClose"
    />

    <ConfirmDialog
      v-if="canBlock"
      :visible="blockDialog"
      @close="blockDialog = false"
      @onConfirm="onBlock"
      @onCancel="blockDialog = false"
      :typeAlert="blocked ? 'error' : 'success'"
    >
    <template v-slot:header>
      {{blockTitle}}
    </template>
    <template v-slot:description>
     {{blockDesc}}
    </template>
    </ConfirmDialog>
  </VDialog>
</template>

<script>
import _ from 'lodash';
import { validationMixin } from 'vuelidate';
import { email, required, requiredIf } from 'vuelidate/lib/validators';
import ConfirmLeftDialog from '@/components/dialog/ConfirmLeftDialog.vue';
import language from '../../mixins/language';
import dialogMixin from '../../mixins/dialogMixin';
import notifications from '../../mixins/notifications';
import usersService from '../../services/request/users/usersService';
import EventBus from '../../events/EventBus';
import depotService from '../../services/request/depot/depotService';
import user from '../../mixins/user';
import phoneMask from '@/validators/phoneMask';
import paymentPlansService from "@/services/request/payment-plans/paymentPlansService";
import {noEngText, noEngTextPasted , onlyNumber} from "../../mixins/helpers";
import { mapGetters } from "vuex";
import noEngTextValidator from "@/validators/noEngText";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";

export default {
  name: 'EditClientDialog',
  mixins: [language, notifications, validationMixin, dialogMixin, user],
  validations: {
    customer: {
      name: { required,noEngTextValidator },
      deport_uuid: {
        // eslint-disable-next-line func-names
        required: requiredIf(function () {
          return this.accessRole;
        }),
      },
      surname: { required,noEngTextValidator },
      phoneNumber: { phoneMask, required },
      email: { email, required },
    },
  },
  components:{
    ConfirmLeftDialog,
    ConfirmDialog,
  },
  props: {
    userItem: {
      required: true,
    },
    type: {
      required: false,
      default:'client'
    },
  },
  async mounted() {
    if(this.permissions.can_view_plans_list){
      this.getPlansList();
    }
    this.updateRoleInfo();
    try {
      this.depots = await depotService.getDepotsList();
    } catch (e) {
      this.setErrorNotification(e);
    }
    // orderDialog('this.userItem', this.userItem);
    this.customer = {
      uuid: this.userItem.uuid,
      name: this.userItem.name,
      company: this.userItem.company || '',
      surname: this.userItem.surname,
      email: this.userItem.email,
      id_state: this.userItem.id_state,
      deport_uuid: this.userItem.uuid_deport,
      phoneNumber: this.userItem.phone.replace('+38',''),
      np_apikey: this.userItem.np_apikey,
      date_crm_end: this.userItem.date_crm_end || null,
      uuid_crm_apikey: this.userItem.uuid_crm_apikey,
      uuid_user_plan: this.userItem.uuid_user_plan,
    };
    this.inputUser = {
      ...this.customer,
    };
  },
  data: () => ({
    onEngText:true,
    customer: {
      uuid: '',
      name: '',
      surname: '',
      company: '',
      phoneNumber: '',
      email: '',
      deport_uuid: '',
      photo: null,
      date_crm_end: null,
      np_apikey: '',
      uuid_crm_apikey: '',
      uuid_user_plan:''
    },
    visibleConfirm:false,
    depots: [],
    accessRole: false,
    photoPreview: null,
    loading: false,
    changed: false,
    datePikerDialog: false,
    paymentPlansList:{},
    blocked: false,
    blockTitle: null,
    blockDesc: null,
    blockDialog: false,
  }),
  watch: {
    customer: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.customer, this.inputUser);
        // console.log(this.customer)
        // console.log(this.inputUser)
      },
    },
  },
  methods: {
    noEngText,
    onlyNumber,
    noEngTextPasted,
    async getPlansList() {
      try {
        const plansItem = await paymentPlansService.usersPlansList();
        this.paymentPlansList = plansItem;
        const notSetPlan = {
          name:this.$t('form.not_selected'),
          uuid: '00000000-0000-0000-0000-000000000000'
        }
        this.paymentPlansList.push(notSetPlan)
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
        showConfirmDialog(){
      if(this.changed){
      this.visibleConfirm = true
      }else{
        this.onClose();
      }
    },
    blockUser(check) {
      console.log(check)
      this.blocked = check
      if (this.blocked) {
        this.blockTitle = this.$t('confirmDialog.block_user.title')
        this.blockDesc = this.$t('confirmDialog.block_user.desc')
      }
      else {
        this.blockTitle = this.$t('confirmDialog.unblock_user.title')
        this.blockDesc = this.$t('confirmDialog.unblock_user.desc')
      }
      this.blockDialog = true
    },
    updateRoleInfo() {
      if (this.permissions.can_create_user_deport) {
        this.accessRole = true;
        // this.validations.user.deport_uuid.required = true;
      }
    },
    onClose() {
      this.$v.$reset();
      this.visibility = false;
    },
    onBlock() {
      if (this.blocked) {
        this.customer.id_state = "250"
      }
      else this.customer.id_state = "210"

      this.onUpdate()
    },
    async onUpdate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append('uuid', this.customer.uuid);
          if (this.customer.photo) {
            formData.append('image', this.customer.photo);
          }
          // if (this.customer.uuid_crm_apikey) {
          //   formData.append('uuid_crm_apikey', this.customer.uuid_crm_apikey);
          // }
          const changedData = this.$getChangedData(this.customer, this.inputUser);
          changedData.forEach(data => {
            formData.append(data.field, data.value);
          });
          await usersService.updateUser(formData);
          this.setSuccessNotification(this.$t('users.user_update_success'));
          this.loading = false;
          this.$emit('resetFilter',true)
          EventBus.$emit('updated-user');
          this.visibility = false;
          this.$v.$reset();
        } catch (e) {
          this.loading = false;
          this.setErrorNotification(e);
        }
      }
    },
    makePreview() {
      let photoPreview = null;
      const { photo } = this.customer;
      const reader = new FileReader();
      reader.readAsDataURL(photo);
      reader.onloadend = (ev) => {
        photoPreview = ev.target.result;
        this.photoPreview = photoPreview;
      };
    },
  },
  computed: {
     ...mapGetters(["currentLanguage"]),
    uuidErrors() {
      const errors = [];
      if (!this.$v.customer.deport_uuid.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.deport_uuid.required
      && errors.push(this.$t('form.errors.StorageRequired'));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.customer.name.$dirty) {
        return errors;
      }
      if (!this.$v.customer.name.noEngTextValidator) {
        errors.push(this.$t("form.hint.NameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.name.required &&
      errors.push(this.$t('form.errors.NameRequired'));
      return errors;
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.customer.surname.$dirty) {
        return errors;
      }
      if (!this.$v.customer.surname.noEngTextValidator) {
        errors.push(this.$t("form.hint.SurnameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.surname.required &&
      errors.push(this.$t('form.errors.SurnameRequired'));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.customer.phoneNumber.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.phoneNumber.required
      &&  errors.push(this.$t('form.errors.PhoneNumberRequired'));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.phoneNumber.phoneMask
      &&  errors.push(this.$t('form.errors.PhoneNumberMustBeLike'));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.customer.email.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.email.required
      && errors.push(this.$t('form.errors.EmailRequired'));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.email.email
      &&  errors.push(this.$t('form.errors.EmailMustBeValid'));
      return errors;
    },
  },
};
</script>

<style scoped>

</style>
